import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import '@fortawesome/fontawesome-free/css/all.css';

import { Card, Carousel } from 'react-bootstrap';

import FsLightbox from "fslightbox-react";
import ModalUI from "../components/common/ModalUI";
import EngagementForm from "../components/forms/EngagementForm";
import Trot from "../components/statics/activites/Trot";
import Galop from "../components/statics/activites/Galop";
import Driver from "../components/statics/activites/Driver";
import Attelage from "../components/statics/activites/Attelage";
import axiosService from "../services/axiosService";
import { formatEventTimes } from "../services/commonService";

function WebApp() {
  // Articles
  const [articles, setArticles] = useState(null);
  // Loading
  const [loading, setLoading] = useState(true);
  // Modal
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (title) => {
    setModalTitle(title);
    setShow(true);
  };
  // Comite
  const [comitesAdministrateursTab, setComitesAdministrateursTab] = useState(true);
  const [comitesAdjointsTab, setComitesAdjointsTab] = useState(false);
  const [comitesMembresTab, setComitesMembresTab] = useState(false);
  // Evenements
  const [evenementsPassesTab, setEvenementsPassesTab] = useState(false);
  const [evenementsProchainsTab, setEvenementsProchainsTab] = useState(true);
  const [evenementsPasses, setEvenementsPasses] = useState(null);
  const [evenementsProchains, setEvenementsProchains] = useState(null);
  const optionsDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  // Contacts
  const [nousEcrireTab, setSousEcrireTab] = useState(true);
  const [nousSituerTab, setNousSituerTab] = useState(false);
  // Medias togglers
  const [togglerImages, setTogglerImages] = useState(false);
  const [togglerVideos, setTogglerVideos] = useState(false);
  // Return to top
  const [returnTop, setReturnTop] = useState(false);
  // Loading Map
  const [loadingContactMap, setLoadingContactMap] = useState(true);

  const showHideComiteTabs = (tab) => {
    setComitesAdministrateursTab(false);
    setComitesAdjointsTab(false);
    setComitesMembresTab(false);

    switch (tab) {
      case 'ComiteAdministrateurs' :
        setComitesAdministrateursTab(true);
        break;
      case 'ComiteAdjoints' :
        setComitesAdjointsTab(true);
        break;
      case 'ComiteMembres' :
        setComitesMembresTab(true);
        break;
    }
  }

  const showHideEvenementsTabs = (tab) => {
    setEvenementsPassesTab(false);
    setEvenementsProchainsTab(false);

    switch (tab) {
      case 'EvenementsPasses' :
        setEvenementsPassesTab(true);
        break;
      case 'EvenementsProchains' :
        setEvenementsProchainsTab(true);
        break;
    }
  }

  const showHideContactTabs = (tab) => {
    setSousEcrireTab(true);
    setNousSituerTab(false);

    switch (tab) {
      case 'NousEcrire' :
        setSousEcrireTab(true);
        break;
      case 'NousSituer' :
        setNousSituerTab(true);
        setTimeout(() => {
            setLoadingContactMap(false);
        }, 3000);
        break;
    }
  }

  const toggleVisibility = () => {
    if (window.scrollY > 200) {
      setReturnTop(true);
    } else {
      setReturnTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0)
    // scroll.scrollToTop({
    //   duration: 500,
    //   smooth: true,
    // });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosService.get('/front');
        setArticles(response.data.articles);
        const now = new Date();
        const eventsPassed = response.data.evenements.filter(item => new Date(item.date.date) < now);
        const eventsNext = response.data.evenements.filter(item => new Date(item.date.date) > now);
        setEvenementsPasses(eventsPassed);
        setEvenementsProchains(eventsNext);
        if (eventsPassed && eventsPassed.length > 0 && (!eventsNext || eventsNext.length === 0)) {
          showHideEvenementsTabs('EvenementsPasses')
        }
      } catch (error) {
        alert('Erreur de chargement de contenu ...')
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    if (evenementsPasses && evenementsPasses.length > 0 && (!evenementsProchains || evenementsProchains.length === 0)) {
      console.log('111');
      showHideEvenementsTabs('EvenementsPasses')
    }
  }, []);

  useEffect(() => {
    setTogglerImages(false);
    setTogglerVideos(false);

    // Return to Top
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const showImagesToggler = () => {
    setTogglerImages(!togglerImages);
  }

  const showVideosToggler = () => {
    setTogglerVideos(!togglerVideos);
  }

  const dashboardUrl = process.env.REACT_APP_DASHBOARD_URL;

  return (
    <>
      <main>

        <nav className="navbar navbar-expand-lg bg-dark">
            <div className="container">
                <a className="navbar-brand" href="index.html" title="Accueil">
                    <i className="fas fa-horse-head"></i> CHR <span style={{ fontSize: 40 }}>44</span>
                </a>

                <a className="btn custom-btn-maroon d-lg-none ms-auto me-4" href={dashboardUrl} target="_blank"><i className="fas fa-lock"></i> Admin</a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav align-items-lg-center ms-auto me-lg-5">

                        <li className="nav-item">
                            <a className="nav-link click-scroll p-1" href="#section_2"><i className="fas fa-university"></i> L'association</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll p-1" href="#section_3"><i className="fas fa-horse"></i> Disciplines</a>
                        </li>
                        
                        <li className="nav-item">
                            <a className="nav-link click-scroll p-1" href="#section_4"><i className="fas fa-calendar"></i> Evènements</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll p-1" href="#section_5"><i className="fas fa-film"></i> Médias</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll p-1" href="#section_6"><i className="fas fa-edit"></i> Contact</a>
                        </li>
                    </ul>

                    <a className="btn custom-btn-maroon d-lg-block d-none" href={dashboardUrl} target="_blank"><i className="fas fa-lock"></i> Admin</a>
                </div>
            </div>
        </nav>

        {/************** Modal ***************/}
        <ModalUI show={show} handleClose={handleClose} title={modalTitle} closeButton={modalTitle === 'Engagement' ? false : true} >
          {modalTitle === 'Engagement' && <EngagementForm />}
          {modalTitle === 'Trot' && <Trot />}
          {modalTitle === 'Galop' && <Galop />}
          {modalTitle === 'Driver' && <Driver />}
          {modalTitle === 'Attelage' && <Attelage />}
        </ModalUI>

        {/************ Home Page *************/}
        <section className="hero-section" id="section_1">
            <div className="section-overlay"></div>

            <div className="container d-flex justify-content-center align-items-center">
                <div className="row">

                    <div className="col-12 mt-auto mb-5 text-center">
                        <small>Fédération Départementale Des Courses Hippiques Rurales De Loire Atlantique</small>

                        <h1 className="text-white mb-5">Courses Hippiques Rurales</h1>

                        <a className="btn custom-btn smoothscroll" onClick={() => handleShow('Engagement')}><i className="fas fa-edit"></i> Nous rejoindre</a>
                    </div>

                    <div className="col-lg-12 col-12 mt-auto d-flex flex-column flex-lg-row text-center">
                        <div className="date-wrap">
                            <h5 className="text-white">
                                <a href="#section_4" title="Prochains évènements">
                                    <i className="custom-icon bi-calendar me-2"></i>
                                </a>
                                Du 15 au 17 <sup>inclus</sup>, Mai 2024
                            </h5>
                        </div>

                        <div className="location-wrap mx-auto py-3 py-lg-0">
                            <h5 className="text-white">
                                <i className="custom-icon bi-geo-alt me-2"></i>
                                23 rue de la grande maison 44119, Treillières
                            </h5>
                        </div>

                        <div className="social-share">
                            <ul className="social-icon d-flex align-items-center justify-content-center">
                                <span className="text-white me-3">Retrouvez-nous sur</span>

                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link" title="Facebook">
                                        <span className="bi-facebook"></span>
                                    </a>
                                </li>

                                {/* <li className="social-icon-item">
                                    <a href="#" className="social-icon-link">
                                        <span className="bi-twitter"></span>
                                    </a>
                                </li> */}

                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link" title="Tiktok">
                                        <span className="bi-tiktok"></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="video-wrap">
                <video autoPlay={true} loop={true} muted={true} className="custom-video" poster="">
                    <source src="./festava-live/video/pexels-2022395.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </section>
        
        {/************ Association *************/}
        <section className="about-section section-padding" id="section_2">
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-12 text-center">
                        <h2 className="text-white mb-4"><i className="fas fa-university"></i> L'association</h2>
                    </div>

                    <div className="col-lg-6 col-12">
                        <div className="about-text-wrap p-2">

                            <div className="about-text-info d-flex p-2" style={{ color: '#7D4427' }}>
                                <div className="d-flex p-auto">
                                    <i className="fa-solid fa-people-group" style={{ fontSize: 32, marginLeft: 15, marginRight: -8 }}></i>
                                </div>
                                <div className="ms-4">
                                    <h3>Refondée en mars 2024</h3>
                                </div>
                            </div>

                            <img src="./festava-live/images/animaux/trolls.jpeg" className="about-image img-fluid bg-success p-1"  />
                        </div>
                    </div>

                    <div className="col-12 mb-4 mb-lg-0 d-flex align-items-center">
                        {loading ? <p>Chargement...</p> :
                        <div className="services-info">
                            {articles.map((article, index) => (
                                article.type === 'Association' &&
                                    <p key={index} >
                                        <h6 className="text-white mt-4">{article.titre}</h6>
                                        <p className="text-white" dangerouslySetInnerHTML={{ __html: article.contenu }} />
                                    </p>
                            ))}
                        </div>}
                    </div>

                </div>

                <div className="row">

                    <div className="col-xl-5 col-lg-7 col-md-10 col-sm-12 col-xs-12 mx-auto">
                        <h3 className="text-center m-4 text-white">Comité</h3>

                        <nav className="d-flex justify-content-center">
                            <div className="nav nav-tabs align-items-baseline justify-content-center bg-success p-1" id="nav-tab"
                                role="tablist">
                                <button className="nav-link active p-2" id="administrateurs-tab" data-bs-toggle="tab" style={{ margin: 2 }}
                                    data-bs-target="#nav-administrateurs" type="button" role="tab"
                                    aria-controls="nav-administrateurs" aria-selected="false" onClick={() => showHideComiteTabs('ComiteAdministrateurs')}>
                                    <h7>Administrateurs</h7>
                                </button>

                                <button className="nav-link p-2" id="adjoints-tab" data-bs-toggle="tab" style={{ margin: 2 }}
                                    data-bs-target="#nav-adjoints" type="button" role="tab"
                                    aria-controls="nav-adjoints" aria-selected="false" onClick={() => showHideComiteTabs('ComiteAdjoints')}>
                                    <h7>Adjoints</h7>
                                </button>

                                <button className="nav-link p-2" id="membres-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-membres" type="button" role="tab"
                                    aria-controls="nav-membres" aria-selected="false" onClick={() => showHideComiteTabs('ComiteMembres')}>
                                    <h7>Membres</h7>
                                </button>
                            </div>
                        </nav>

                        {/* Administrateurs */}
                        { comitesAdministrateursTab &&
                          <div className="tab-content shadow-lg mt-5 p-1" id="nav-tabContent" style={{ backgroundColor: 'transparent', border: 2, borderColor: '#A2C523', borderStyle: 'solid', color: '#A2C523' }}>
                            <div className="tab-pane fade show active" id="nav-administrateurs" role="tabpanel"
                                aria-labelledby="administrateurs-tab">                                
                                
                                <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                    <div className="carousel-inner shadow-lg"  style={{ borderRadius: 15, height: 650 }}>
                                        <div className="carousel-item active shadow-lg">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card" style={{ width: '100%' }}>
                                                        <img src="./festava-live/images/comite/administrateurs/administrateur_1.jpg" className="card-img-top" alt="..." />
                                                        <div className="card-body mt-3" >
                                                            <h5 className="card-title">Président</h5>
                                                            <p className="card-text" style={{ fontSize: 15 }}>
                                                                <i>Mail</i> : federation_des_courses_de_pays@gmail.com <br />
                                                                <i>Tél</i> : 06.81.22.43.44
                                                            </p>
                                                            <a href="#" className="btn custom-btn ">Contacter</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card" style={{ width: '100%' }}>
                                                        <img src="./festava-live/images/comite/administrateurs/administrateur_2.jpg" className="card-img-top" alt="..." />
                                                        <div className="card-body mt-3" style={{ minHeight: 180 }}>
                                                            <h5 className="card-title">Vice Président</h5>
                                                            <p className="card-text" style={{ fontSize: 15 }}>
                                                                CHRISTOPHE GALLON<br />
                                                                <i>Tél</i> : 07.52.67.32.71
                                                            </p>
                                                            <a href="#" className="btn custom-btn ">Contacter</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style={{ marginTop: 10 }}>
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>

                                </div>

                            </div>
                          </div>
                        }

                        {/* Adjoints */}
                        { comitesAdjointsTab &&
                          <div className="tab-content shadow-lg mt-5 p-1" id="nav-tabContent" style={{ backgroundColor: 'transparent', border: 2, borderColor: '#A2C523', borderStyle: 'solid', color: '#A2C523' }}>
                            <div className="tab-pane fade show active" id="nav-adjoints" role="tabpanel"
                                aria-labelledby="adjoints-tab">                                
                                
                                <div id="carouselExampleControlsAdjoints" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                    <div className="carousel-inner shadow-lg"  style={{ borderRadius: 15, height: 650 }}>
                                        <div className="carousel-item active shadow-lg">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card" style={{ width: '100%' }}>
                                                        <img src="./festava-live/images/comite/adjoints/secretaire.jpg" className="card-img-top" alt="..." />
                                                        <div className="card-body mt-3" >
                                                            <h5 className="card-title">Secrétaire</h5>
                                                            <p className="card-text" style={{ fontSize: 15 }}>
                                                                ANNE HERRMANN <br />
                                                                <i>Tél</i> : 06.49.59.80.64
                                                            </p>
                                                            
                                                            <a href="#" className="btn custom-btn ">Contacter</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card" style={{ width: '100%' }}>
                                                        <img src="./festava-live/images/comite/adjoints/secretaire_adjoint.jpg" className="card-img-top" alt="..." />
                                                        <div className="card-body mt-3" style={{ minHeight: 180 }}>
                                                            <h5 className="card-title">Secrétaire Adjoint</h5>
                                                            <p className="card-text" style={{ fontSize: 15 }}>
                                                                JACKY RONZEAU <br />
                                                                <i>Tél</i> : 07.66.49.74.85
                                                            </p>
                                                            <a href="#" className="btn custom-btn ">Contacter</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card" style={{ width: '100%' }}>
                                                        <img src="./festava-live/images/comite/adjoints/tresorier.jpg" className="card-img-top" alt="..." />
                                                        <div className="card-body mt-3" style={{ minHeight: 180 }}>
                                                            <h5 className="card-title">Tésorier</h5>
                                                            <p className="card-text" style={{ fontSize: 15 }}>
                                                                BAPTISTE ROUSSIÈRE <br />
                                                                <i>Tél</i> : 06.06.06.06.06
                                                            </p>
                                                            <a href="#" className="btn custom-btn ">Contacter</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card" style={{ width: '100%' }}>
                                                        <img src="./festava-live/images/comite/adjoints/tresorier_adjoint.jpg" className="card-img-top" alt="..." />
                                                        <div className="card-body mt-3" style={{ minHeight: 180 }}>
                                                            <h5 className="card-title">Tésorier Adjoint</h5>
                                                            <p className="card-text" style={{ fontSize: 15 }}>
                                                                JÉRÉMY GALLON<br />
                                                                <i>Tél</i> : 06.06.06.06.06
                                                            </p>
                                                            <a href="#" className="btn custom-btn ">Contacter</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsAdjoints" data-bs-slide="prev" style={{ marginTop: 10 }}>
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsAdjoints" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>

                                </div>

                            </div>
                          </div>
                        }

                        {/* Membres */}
                        { comitesMembresTab &&
                          <div className="tab-content shadow-lg mt-5 p-1" id="nav-tabContent" style={{ backgroundColor: 'transparent', border: 2, borderColor: '#A2C523', borderStyle: 'solid', color: '#A2C523' }}>
                            <div className="tab-pane fade show active" id="nav-membres" role="tabpanel"
                                aria-labelledby="membres-tab">

                                <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                    <div className="carousel-inner shadow-lg"  style={{ borderRadius: 15, height: 650 }}>
                                        <div className="carousel-item active shadow-lg">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card" style={{ width: '100%' }}>
                                                        <img src="./festava-live/images/comite/membres/communication.jpg" className="card-img-top" alt="..." />
                                                        <div className="card-body mt-3" >
                                                            <h5 className="card-title">Communication</h5>
                                                            <p className="card-text" style={{ fontSize: 15 }}>
                                                                GILBERT ROUESSARD <br />
                                                                <i>Tél</i> : 06.85.81.32.46
                                                            </p>
                                                            <a href="#" className="btn custom-btn ">Contacter</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card" style={{ width: '100%' }}>
                                                        <img src="./festava-live/images/comite/membres/reseaux_sociaux.jpg" className="card-img-top" alt="..." />
                                                        <div className="card-body mt-3" style={{ minHeight: 180 }}>
                                                            <h5 className="card-title">Réseaux Sociaux</h5>
                                                            <p className="card-text" style={{ fontSize: 15 }}>
                                                                CAMILLE<br />
                                                                <i>Tél</i> : 06.06.06.06.06
                                                            </p>
                                                            <a href="#" className="btn custom-btn ">Contacter</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style={{ marginTop: 10 }}>
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>

                                  </div>

                              </div>
                          </div>
                        }

                    </div>

                </div>
            </div>
        </section>
        
        {/************ Disciplines *************/}
        <section className="artists-section section-padding" id="section_3">
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-12 text-center">
                        <h2 className="mb-4"><i className="fas fa-horse"></i> Disciplines</h2>
                    </div>

                    <div className="col-lg-6 col-12">
                      <div className="artists-thumb">
                          <h5 className="text-center">Le Trot</h5>

                          <div className="artists-image-wrap">
                              <img src="./festava-live/images/activites/trot.jpg" className="artists-image img-fluid" style={{ height: 500 }} />
                          </div>

                          <div className="artists-hover pb-3 pt-3">
                              <p className="mb-1">
                                  <strong>Définition</strong> <br />
                                  Allure <u>naturelle</u> du cheval
                              </p>

                              <p className="mb-1">
                                  <strong>Catégories</strong> <br />
                                  Assis - Enlevé - Equilibré - Arabe
                              </p>

                              <p className="m-0 text-end mt-2 mb-0">
                                  <button className="custom-btn" onClick={() => handleShow('Trot')}>En savoir plus</button>
                              </p>
                          </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-12">
                        <div className="artists-thumb">
                            <h5 className="text-center">Le Galop</h5>

                            <div className="artists-image-wrap">
                                <img src="./festava-live/images/activites/galop.jpg" className="artists-image img-fluid" style={{ height: 500 }} />
                            </div>

                            <div className="artists-hover pb-3 pt-3">
                              <p className="mb-1">
                                  <strong>Définition</strong> <br />
                                  Allure <u>rapide</u> du cheval
                              </p>

                              <p className="mb-1">
                                  <strong>Mécanisme</strong> <br />
                                  Se pratique sur 3 phases (temps)
                              </p>

                              <p className="m-0 text-end mt-2 mb-0">
                                  <button className="custom-btn" onClick={() => handleShow('Galop')}>En savoir plus</button>
                              </p>
                          </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-12">
                        <div className="artists-thumb">
                            <h5 className="text-center">Attelage</h5>

                            <div className="artists-image-wrap">
                                <img src="./festava-live/images/activites/attelage.jpg" className="artists-image img-fluid" style={{ height: 500 }} />
                            </div>

                            <div className="artists-hover pb-3 pt-3">
                              <p className="mb-1">
                                  <strong>Définition</strong> <br />
                                  Cheval attaché à un véhicule hippomobile
                              </p>

                              <p className="mb-1">
                                  <strong>Particularités</strong> <br />
                                  Plusieurs chevaux de front peuvent être utilisés
                              </p>

                              <p className="m-0 text-end mt-2 mb-0">
                                  <button className="custom-btn" onClick={() => handleShow('Attelage')}>En savoir plus</button>
                              </p>
                          </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-12">
                        <div className="artists-thumb">
                            <h5 className="text-center">Driver</h5>
                            <div className="artists-image-wrap">
                                <img src="./festava-live/images/activites/driver.jpg" className="artists-image img-fluid" style={{ height: 500 }} />
                            </div>

                            <div className="artists-hover pb-3 pt-3">
                              <p className="mb-1">
                                  <strong>Définition</strong> <br />
                                  Entretien et entrainement des chevaux
                              </p>

                              <p className="mb-1">
                                  <strong>Principal rôle</strong> <br />
                                  Entretenir les chevaux et les préparer aux courses
                              </p>

                              <p className="m-0 text-end mt-2 mb-0">
                                  <button className="custom-btn" onClick={() => handleShow('Driver')}>En savoir plus</button>
                              </p>
                          </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        {/************ Evènements *************/}
        <section className="schedule-section section-padding" id="section_4">
            <div className="container">
                <div className="row">
                    <div className="col"></div>
                    <div className="col-xs-12 col-10 text-center pb-2 mb-0">
                        <h2 className="text-white mb-3"><i className="fas fa-calendar"></i> Evénements</h2>

                        <nav className="d-flex justify-content-center pt-1 pb-4 mb-4" style={{ marginTop: 50 }}>
                            <div className="nav nav-tabs align-items-baseline justify-content-center bg-success p-1" id="nav-tab"
                                role="tablist">
                                {evenementsPasses && evenementsPasses.length > 0 &&
                                  <button className={!evenementsProchains || evenementsProchains.length === 0 ? 'nav-link active p-2' : 'nav-link p-2'} id="events-passes-tab" data-bs-toggle="tab" style={{ margin: 2, width: '8rem' }}
                                    data-bs-target="#nav-events-passes" type="button" role="tab"
                                    aria-controls="nav-events-passes" aria-selected="false" onClick={() => showHideEvenementsTabs('EvenementsPasses')}>
                                    <h7>Passés</h7>
                                  </button>
                                }
                                {evenementsProchains && evenementsProchains.length > 0 &&
                                  <button className="nav-link active p-2" id="events-prochains-tab" data-bs-toggle="tab" style={{ margin: 2, width: '8rem' }}
                                    data-bs-target="#nav-events-prochains" type="button" role="tab"
                                    aria-controls="nav-events-prochains" aria-selected="false" onClick={() => showHideEvenementsTabs('EvenementsProchains')}>
                                    <h7>Prochains</h7>
                                  </button>
                                }                                
                            </div>
                        </nav>

                        {evenementsPassesTab && 
                            <div className="row tab-content shadow-lg" id="nav-tabContent" style={{ backgroundColor: '#212529', height: '60%', border: 4, paddingRight: '2%', marginBottom: '20%', paddingLeft: '2%', borderColor: '#ccc', borderStyle: 'solid', color: '#A2C523' }}>
                                <Carousel pause='hover' controls={evenementsPasses && evenementsPasses.length > 1}>
                                    {evenementsPasses && evenementsPasses.map((evenementPasse, index) => (
                                        <Carousel.Item >
                                            <Card className="text-center align-middle" style={{ verticalAlign: 'middle', textAlign: 'center', paddingRight: '15%', paddingLeft: '15%', paddingTop: '5%' }}>
                                                <Card.Header className="mb-5" style={{ fontSize: 18, marginTop: -25, color: '#ccc' }}>
                                                    { (new Date(evenementPasse.date.date)).toLocaleDateString('fr-FR', optionsDate) }
                                                    <p>
                                                        <small style={{ marginTop: -10 }}>{ formatEventTimes(evenementPasse.heure_debut?.date, evenementPasse.heure_fin?.date) }</small>
                                                    </p>
                                                </Card.Header>
                                                <Card.Body className="mb-1" >
                                                    <Card.Title style={{ color: '#ccc' }}><p>{evenementPasse.titre}</p></Card.Title>
                                                    <Card.Text>
                                                        <p dangerouslySetInnerHTML={{ __html: evenementPasse.descriptif }} />
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer className="text-muted pb-5">
                                                    <u>Organisateur</u> : {evenementPasse.organisateur} <br />
                                                    <u>Catégorie</u> : {evenementPasse.category ? evenementPasse.category : '-'}
                                                </Card.Footer>
                                            </Card>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                        }

                        {evenementsProchainsTab && 
                            <div className="row tab-content shadow-lg" id="nav-tabContent" style={{ backgroundColor: '#212529', height: '60%', border: 4, paddingRight: '2%', marginBottom: '20%', paddingLeft: '2%', borderColor: '#A2C523', borderStyle: 'solid', color: '#A2C523' }}>
                                <Carousel pause='hover' controls={evenementsProchains && evenementsProchains.length > 1}>
                                    {evenementsProchains && evenementsProchains.map((evenementProchain, index) => (
                                        <Carousel.Item key={index}>
                                            <Card className="text-center align-middle" style={{ verticalAlign: 'middle', textAlign: 'center', paddingRight: '15%', paddingLeft: '15%', paddingTop: '5%' }}>
                                                <Card.Header className="mb-5" style={{ fontSize: 18, marginTop: -25 }}>
                                                    { (new Date(evenementProchain.date.date)).toLocaleDateString('fr-FR', optionsDate) }
                                                    <p>
                                                        <small style={{ marginTop: -10 }}>{ formatEventTimes(evenementProchain.heure_debut?.date, evenementProchain.heure_fin?.date) }</small>
                                                    </p>
                                                </Card.Header>
                                                <Card.Body className="mb-1">
                                                    <Card.Title>{evenementProchain.titre}</Card.Title>
                                                    <Card.Text>
                                                        <p className="text-white" dangerouslySetInnerHTML={{ __html: evenementProchain.descriptif }} />
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer className="text-muted pb-5">
                                                    <u>Organisateur</u> : {evenementProchain.organisateur} <br />
                                                    <u>Catégorie</u> : {evenementProchain.category ? evenementProchain.category : '-'}
                                                </Card.Footer>
                                            </Card>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                        }
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        </section>
              
        {/************ Médias *************/}
        <section className="pricing-section section-padding section-bg" id="section_5">
            <div className="container">
                <div className="row pb-2">

                    <div className="col-lg-8 col-12 mx-auto">
                        <h2 className="text-center mb-4"><i className="fas fa-film"></i> Médias</h2>
                    </div>

                    <div className="col-lg-6 col-12 mx-auto mt-5">
                        <div className="about-text-wrap p-2" onClick={showImagesToggler} style={{ cursor: 'pointer' }}>

                            <div className="about-text-info d-flex p-2" style={{ color: '#7D4427' }}>
                                <div className="d-flex p-auto">
                                    <i className="fas fa-camera" style={{ fontSize: 30, marginLeft: 15, marginRight: -8 }}></i>
                                </div>
                                <div className="ms-4">
                                    <h3>Photos</h3>
                                </div>
                            </div>

                            <img src="./festava-live/images/activites/galop.jpg" className="about-image img-fluid bg-success p-1" style={{ height: 400, width: '100%' }} />
                        </div>
                    </div>

                    <div className="col-lg-6 col-12 mx-auto mt-5 video-thumbnail">
                        <div className="about-text-wrap p-2" onClick={showVideosToggler} style={{ cursor: 'pointer' }}>

                            <div className="about-text-info d-flex p-2" style={{ color: '#7D4427' }}>
                                <div className="d-flex p-auto">
                                    <i className="fas fa-video" style={{ fontSize: 30, marginLeft: 15, marginRight: -8 }}></i>
                                </div>
                                <div className="ms-4">
                                    <h3>Vidéos</h3>
                                </div>
                            </div>

                            <img src="./festava-live/images/animaux/trolls.jpeg" className="about-image img-fluid bg-success p-1" style={{ height: 400, width: '100%' }} />
                            <div className="play-icon">&#9658;</div>
                        </div>
                    </div>
                    
                    <FsLightbox
                      toggler={togglerImages}
                      sources={[
                        './festava-live/images/activites/attelage_1.jpg',
                        './festava-live/images/activites/attelage_2.jpg',
                        './festava-live/images/activites/course_1.jpg',
                        './festava-live/images/activites/course_2.jpg',
                      ]}
                    />
                    
                    <FsLightbox
                      toggler={togglerVideos}
                      sources={[
                        './festava-live/video/pexels-2022395.mp4',
                      ]}
                    />

                    {/* <div className="col-lg-6 col-12">
                        <div className="pricing-thumb">
                            <div className="d-flex">
                                <div>
                                    <h3><small>Early Bird</small> $120</h3>

                                    <p>Including good things:</p>
                                </div>

                                <p className="pricing-tag ms-auto">Save up to <span>50%</span></p>
                            </div>

                            <ul className="pricing-list mt-3">
                                <li className="pricing-list-item">platform for potential customers</li>

                                <li className="pricing-list-item">digital experience</li>

                                <li className="pricing-list-item">high-quality sound</li>

                                <li className="pricing-list-item">standard content</li>
                            </ul>

                            <a className="link-fx-1 color-contrast-higher mt-4" href="ticket.html">
                                <span>Buy Ticket</span>
                                <svg className="icon" viewBox="0 0 32 32" aria-hidden="true">
                                    <g fill="none" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round">
                                        <circle cx="16" cy="16" r="15.5"></circle>
                                        <line x1="10" y1="18" x2="16" y2="12"></line>
                                        <line x1="16" y1="12" x2="22" y2="18"></line>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div> */}

                    {/* <div className="col-lg-6 col-12 mt-4 mt-lg-0">
                        <div className="pricing-thumb">
                            <div className="d-flex">
                                <div>
                                    <h3><small>Standard</small> $240</h3>

                                    <p>What makes a premium festava?</p>
                                </div>
                            </div>

                            <ul className="pricing-list mt-3">
                                <li className="pricing-list-item">platform for potential customers</li>

                                <li className="pricing-list-item">digital experience</li>

                                <li className="pricing-list-item">high-quality sound</li>

                                <li className="pricing-list-item">premium content</li>
                            </ul>

                            <a className="link-fx-1 color-contrast-higher mt-4" href="ticket.html">
                                <span>Buy Ticket</span>
                                <svg className="icon" viewBox="0 0 32 32" aria-hidden="true">
                                    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                                        <circle cx="16" cy="16" r="15.5"></circle>
                                        <line x1="10" y1="18" x2="16" y2="12"></line>
                                        <line x1="16" y1="12" x2="22" y2="18"></line>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div> */}

                </div>
            </div>
        </section>
        
        {/************ Contact *************/}
        <section className="about-section section-padding" id="section_6">
            <div className="container">
                <div className="row">

                    <div className="col-lg-8 col-12 mx-auto mb-5">
                        <h2 className="text-center mb-4 text-white"><i className="fas fa-edit"></i> Contact</h2>

                        <nav className="d-flex justify-content-center pt-3 pb-4 mb-3">
                            <div className="nav nav-tabs align-items-baseline justify-content-center bg-success p-1" id="nav-tab"
                                role="tablist">
                                <button className="nav-link active p-2" id="nav-ContactForm-tab" onClick={() => setLoadingContactMap(false)} data-bs-toggle="tab" style={{ margin: 2, width: '8rem' }}
                                    data-bs-target="#nav-ContactForm" type="button" role="tab"
                                    aria-controls="nav-ContactForm" aria-selected="false" onClick={() => showHideContactTabs('NousEcrire')}>
                                    <h7><i className="fas fa-pencil-alt"></i> Nous écrire</h7>
                                </button>

                                <button className="nav-link p-2" id="nav-ContactMap-tab" data-bs-toggle="tab" style={{ margin: 2, width: '8rem' }}
                                    data-bs-target="#nav-ContactMap" type="button" role="tab"
                                    aria-controls="nav-ContactMap" aria-selected="false" onClick={() => showHideContactTabs('NousSituer')}>
                                    <h7><i className="fas fa-street-view"></i> Nous situer</h7>
                                </button>
                            </div>
                        </nav>

                        <div className="tab-content shadow-lg mt-5" id="nav-tabContent">
                            { nousEcrireTab && 
                              <div className="tab-pane fade show active" id="nav-ContactForm" role="tabpanel"
                                  aria-labelledby="nav-ContactForm-tab">
                                  <form className="custom-form contact-form mb-5 mb-lg-0" action="#" method="post" role="form">
                                      <div className="contact-form-body">
                                          <div className="row">
                                              <div className="col-lg-6 col-md-6 col-12">
                                                  <input type="text" name="contact-name" id="contact-name" className="form-control" placeholder="Nom complet" required />
                                              </div>

                                              <div className="col-lg-6 col-md-6 col-12">
                                                  <input type="email" name="contact-email" id="contact-email"
                                                      pattern="[^ @]*@[^ @]*" className="form-control"
                                                      placeholder="Adresse e-mail" required />
                                              </div>
                                          </div>

                                          <input type="text" name="contact-company" id="contact-company"
                                              className="form-control" placeholder="Entreprise / Association" required />

                                          <textarea name="contact-message" rows="5" className="form-control" required
                                              id="contact-message" placeholder="Message"></textarea>

                                          <div className="col-lg-4 col-md-10 col-8 mx-auto">
                                              <button type="submit" className="form-control custom-btn"><i className="fas fa-paper-plane"></i> Envoyer</button>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                            }

                            { nousSituerTab && 
                              <div className="tab-pane fade show active" id="nav-ContactMap" role="tabpanel"
                                  aria-labelledby="nav-ContactMap-tab">
                                <div style={{ width: '100%', height: 370 }} >
                                    {loadingContactMap ?
                                        <p style={{ textAlign: 'center', paddingTop: '20%' }}>
                                            Chargement de la carte ...
                                        </p>
                                    :
                                    <iframe width="100%" height="370" style={{ padding: 0 }} src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=23%20rue%20de%20la%20grande%20maison%2044119,%20Treilli%C3%A8res+(F%C3%89D%C3%89RATION%20D%C3%89PARTEMENTAL%20DES%20COURSES%20HIPPIQUES%20RURALES)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/sport-gps/">hiking gps</a></iframe>
                                    }
                                </div>
                              </div>
                            }
                        </div>

                    </div>

                </div>
            </div>
        </section>

        {returnTop && (
          <button onClick={scrollToTop} className="go-to-top" title="Vers le haut">
            <i className="fas fa-angle-up"></i>
          </button>
        )}

      </main>

      <Footer />
    </>
  );
}

export default WebApp;
